import React from 'react';
import './App.css';
import CustomFormValidation from './components/registerForm';

function App() {
  return (
    <CustomFormValidation />
  );
}

export default App;