import React from 'react'
import '../App.css'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const defaultState = {
    nome: null,
    email: null,
    password: null,
    phone_number: null,
    phone_numberError: null,
    nomeError: null,
    emailError: null,
    passwordError: null,
    loading: false
}
class CustomFormValidation extends React.Component {
    constructor() {
        super();
        this.state = defaultState;
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    submit() {
        if (this.validate()) {
            this.setState({ loading: true });
            const payload = {
                email: this.state.email,
                password: this.state.password,
                nome: this.state.nome,
                phone_number: this.state.phone_number,
                pagando: true
            };

            axios.post('https://api.gerapromos.com.br/users', payload)
                .then(response => {
                    this.setState(defaultState);
                    window.location.href = 'https://t.me/GeraPromos_Bot';
                })
                .catch(error => {
                    console.error('Erro ao cadastrar:', error);
                    this.setState({ loading: false });
                });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    validate() {
        let nomeError = "";
        let emailError = "";
        let passwordError = "";
        let phone_numberError = "";
        if (!this.state.nome) {
            nomeError = "Nome é Obrigatório";
        }
        if (!this.state.phone_number) {
            phone_numberError = "Telefone é obrigatório"
        }
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.email || reg.test(this.state.email) === false) {
            emailError = "Email é obrigatório ";
        }
        if (!this.state.password) {
            passwordError = "Senha é obrigatória";
        }
        if (emailError || nomeError || passwordError) {
            this.setState({ nomeError, emailError, passwordError, phone_numberError });
            return false;
        }
        return true;
    }
    render() {
        return (
            <div className="App">
                <div class="container-fluid ps-md-0">
                    <div class="row g-0">
                        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
                        <div class="col-md-8 col-lg-6">
                            <div class="login d-flex align-items-center py-5">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-9 col-lg-8 mx-auto">
                                            <h3 class="login-heading mb-4">Último passo será esse cadastro!</h3>
                                            <form>

                                                <div class="form-floating mb-3">
                                                    <input type="text" className={"form-control " + (this.state.nomeError ? 'invalid' : '')} id="floatingPassword" name="nome" placeholder="Nome" value={this.state.nome} onChange={this.handleInputChange} />
                                                    <label for="floatingPassword">Nome</label>
                                                    <span className="text-danger">{this.state.nomeError}</span>
                                                </div>

                                                <div class="form-floating mb-3">
                                                    <input type="email" className={"form-control " + (this.state.emailError ? 'invalid' : '')} id="floatingInput" name='email' placeholder="name@example.com" value={this.state.email} onChange={this.handleInputChange} />
                                                    <label for="floatingInput">Email</label>
                                                    <span className="text-danger">{this.state.emailError}</span>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input type="password" className={"form-control " + (this.state.passwordError ? 'invalid' : '')} id="floatingPassword" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} />
                                                    <label for="floatingPassword">Senha</label>
                                                    <span className="text-danger">{this.state.passwordError}</span>
                                                </div>

                                                <div class="form-floating mb-3">
                                                    <input type="text" className={"form-control " + (this.state.phone_numberError ? 'invalid' : '')} id="floatingPassword" name="phone_number" placeholder="Telefone" value={this.state.phone_number} onChange={this.handleInputChange} />
                                                    <label for="phone_number">Telefone (Whatsapp)</label>
                                                    <span className="text-danger">{this.state.phone_numberError}</span>
                                                </div>

                                                <div class="d-grid">
                                                    <button className="btn-login" type="button" onClick={() => this.submit()} disabled={this.state.loading}>
                                                        {this.state.loading ? <div className="spinner-border text-light" role="status"><span className="visually-hidden">Loading...</span></div> : 'Cadastrar no Bot'}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CustomFormValidation;